<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">No. STK</label>
        <vs-input class="w-full" :value="initData.header.no_stk" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl. STK</label>
        <vs-input class="w-full" :value="initData.header.tgl_stk" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">STK Ke / BAST Ke</label>
        <vs-input class="w-full" :value="initData.header.stk_ke" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl. Awal Pelaksanaan</label>
        <vs-input class="w-full" :value="initData.header.awal_pelaksanaan" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl. Akhir Pelaksanaan</label>
        <vs-input class="w-full" :value="initData.header.akhir_pelaksanaan" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl. Akhir Realisasi</label>
        <vs-input class="w-full" :value="initData.header.realisasi_pelaksanaan || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Progress Real (%)</label>
        <vs-input class="w-full" :value="initData.header.progress_real || '-'" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3" v-if="initData.header.stk_ke < 2">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Masa Pemeliharaan (Hari)</label>
        <vs-input class="w-full" :value="initData.header.masa_pemeliharaan" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Tgl. Akhir Pemeliharaan</label>
        <vs-input class="w-full" :value="initData.header.akhir_tgl_pemeliharaan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Keterangan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.header.keterangan" readonly />
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Foto</label>
        <div @click="showImages(initData.header.gambar_url)">
          <vx-input-group>
            <vs-input v-if="initData.header.gambar_url" :value="`${initData.header.gambar_url.length} Gambar`" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-image">Lihat</vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">File</label>
        <div @click="showFiles(initData.header.file_url)">
          <vx-input-group>
            <vs-input v-if="initData.header.file_url" :value="`${initData.header.file_url.length} File`" readonly/>
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button type="filled" icon-pack="feather" icon="icon-file">Lihat</vs-button>
              </div>
            </template>
          </vx-input-group>
        </div>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modals-->
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'TabDetailStk',
  props: {
    initData: {
      default: null,
      type: Object
    }
  },
  components: {
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    ImageViewer: () => import('@/views/components/image-viewer/ImageViewer')
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    showFiles (filesUrl) {
      this.modalFiles.filesUrl = filesUrl
      this.modalFiles.active = true
    },

    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.stkApproval.activeTab
      this.$store.commit('approvals/stkApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
